import Swiper from "swiper";
import { Pagination } from "swiper/modules";

export class ReviewsCarousel extends HTMLElement {
  container: HTMLElement | null;
  pagination: HTMLElement | null;

  constructor() {
    super();

    this.container = this.querySelector<HTMLElement>(".swiper");
    this.pagination = this.querySelector<HTMLElement>(".swiper-pagination");

    if (this.container !== null) {
      new Swiper(this.container, {
        modules: [Pagination],
        slidesPerView: "auto",
        spaceBetween: 28,
        pagination: {
          el: this.pagination,
          type: "progressbar",
        },
      });
    }
  }
}
