export class LazyVideo extends HTMLElement {
  private observer: IntersectionObserver;

  constructor() {
    super();

    const video = this.querySelector("video");

    this.observer = new IntersectionObserver((entries) => {
      for (const entry of entries) {
        if (entry.isIntersecting === true) {
          video?.setAttribute("src", video.getAttribute("data-src") ?? "");

          this.observer.disconnect();
        }
      }
    });
  }

  connectedCallback() {
    this.observer.observe(this);
  }

  disconnectedCallback() {
    this.observer.unobserve(this);
  }
}
