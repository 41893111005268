import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

export const createCardsSection = () => {
  new Swiper(".theme-list.is-ltr", {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 20,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 0,
      reverseDirection: true,
      pauseOnMouseEnter: true,
    },
  });

  new Swiper(".theme-list.is-rtl", {
    modules: [Autoplay],
    slidesPerView: "auto",
    spaceBetween: 20,
    loop: true,
    speed: 2000,
    autoplay: {
      delay: 1,
      reverseDirection: false,
      pauseOnMouseEnter: true,
    },
  });
};
