import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

export const createSectionMain = () => {
  const container = document.querySelector<HTMLElement>(
    ".section-main .swiper",
  );

  if (container !== null) {
    new Swiper(container, {
      modules: [Autoplay],
      slidesPerView: "auto",
      spaceBetween: 80,
      loop: true,
      speed: 2000,
      autoplay: {
        delay: 3000,
        reverseDirection: false,
        pauseOnMouseEnter: true,
      },
    });
  }
};
