type FocusListener = (element: Element, listener: () => void) => void;
type BlurListener = (element: Element, listener: () => void) => void;

const blurElements = new Map<Element, (() => void)[]>();

const blurSimulationHandler = (event: MouseEvent | TouchEvent) => {
  blurElements.forEach((listeners, element) => {
    const target = event.target as Node | null;

    if (element !== target && element.contains(target) === false) {
      listeners.forEach((listener) => listener());
    }
  });
};

export const onFocus: FocusListener = (element, listener) => {
  element.addEventListener("click", listener);
};

export const offFocus: FocusListener = (element, listener) => {
  element.removeEventListener("click", listener);
};

export const onBlur: BlurListener = (element, listener) => {
  const listeners = blurElements.get(element);

  if (listeners !== undefined) {
    listeners.push(listener);
  } else {
    blurElements.set(element, [listener]);
  }
};

export const offBlur: BlurListener = (element, listener) => {
  const listeners = blurElements.get(element);

  if (listeners !== undefined) {
    blurElements.set(
      element,
      listeners.filter((l) => listener !== l),
    );
  }
};

document.addEventListener("mousedown", (event) => {
  blurSimulationHandler(event);
});

document.addEventListener("touchstart", (event) => {
  blurSimulationHandler(event);
});
