export class MasonryGrid extends HTMLElement {
  private items: HTMLElement[];
  private resizeObserver: ResizeObserver;
  private active: boolean = false;

  constructor() {
    super();

    this.items = Array.from(this.children) as HTMLElement[];

    this.resizeObserver = new ResizeObserver(() => {
      this.update();
    });
  }

  private getStyleProperties = () => {
    const style = window.getComputedStyle(this);

    const gap = parseInt(style.columnGap);
    const columns = style.gridTemplateColumns.split(/\s+/).length;

    return {
      gap: isNaN(gap) ? 0 : gap,
      columns,
    };
  };

  private update = () => {
    const { gap, columns } = this.getStyleProperties();

    if (columns > 1) {
      for (let column = 0; column < columns; column++) {
        const items = this.items.filter(
          (_, index) => (index + column) % columns === 0,
        );

        const startingOffset = this.getBoundingClientRect().top ?? 0;

        items.reduce((offset, item) => {
          const { height, top } = item.getBoundingClientRect();
          const marginTop = parseInt(item.style.getPropertyValue("margin-top"));
          const margin = isNaN(marginTop) ? 0 : marginTop;

          const originalOffset = top - margin;

          item.style.setProperty(
            "margin-top",
            `${-Math.floor(Math.abs(offset - originalOffset))}px`,
          );

          return offset + height + gap;
        }, startingOffset);
      }
    } else {
      this.items.forEach((item) => {
        item.style.removeProperty("margin-top");
      });
    }
  };

  create() {
    if (this.active) {
      return;
    }

    this.active = true;

    window.addEventListener("resize", this.update);

    document.addEventListener("DOMContentLoaded", this.update);

    this.items.forEach((item) => {
      this.resizeObserver.observe(item);
    });

    this.update();
  }

  destroy() {
    this.active = false;
    window.removeEventListener("resize", this.update);
    this.resizeObserver.disconnect();
  }

  connectedCallback() {
    this.create();
  }

  disconnectedCallback() {
    this.destroy();
  }
}
