type MasonryProps = {
  getColumns: () => () => number;
  getGap: () => () => number;
};

export class Masonry {
  private container: HTMLElement | null;
  private items: HTMLElement[];
  private getColumns: () => number;
  private getGap: () => number;
  private resizeObserver: ResizeObserver;
  private active: boolean = false;

  constructor(selector: string, options: MasonryProps) {
    this.container = document.querySelector(selector);
    this.items = Array.from(
      this.container?.querySelectorAll("[data-masonry-item]") ?? [],
    );
    this.getColumns = options.getColumns();
    this.getGap = options.getGap();

    this.resizeObserver = new ResizeObserver(() => {
      this.update();
    });

    this.create();
  }

  update = () => {
    const columns = this.getColumns();
    const gap = this.getGap();

    for (let column = 0; column < columns; column++) {
      const items = this.items.filter(
        (_, index) => (index + column) % columns === 0,
      );

      const startingOffset = this.container?.getBoundingClientRect().top ?? 0;

      items.reduce((offset, item) => {
        const { height, top } = item.getBoundingClientRect();
        const marginTop = parseInt(item.style.getPropertyValue("margin-top"));
        const margin = isNaN(marginTop) ? 0 : marginTop;

        const originalOffset = top - margin;

        item.style.setProperty(
          "margin-top",
          `${-Math.floor(Math.abs(offset - originalOffset))}px`,
        );

        return offset + height + gap;
      }, startingOffset);
    }
  };

  create() {
    if (this.active) {
      return;
    }

    this.active = true;

    window.addEventListener("resize", this.update);

    document.addEventListener("DOMContentLoaded", this.update);

    this.items.forEach((item) => {
      this.resizeObserver.observe(item);
    });

    this.update();
  }

  destroy() {
    this.active = false;
    window.removeEventListener("resize", this.update);
    this.resizeObserver.disconnect();
  }
}
