export class FaqItem extends HTMLElement {
  header: Element | null;

  constructor() {
    super();

    this.header = this.querySelector(".item-header");

    this.header?.addEventListener("click", () => {
      this.classList.toggle("is-active");
    });
  }
}
