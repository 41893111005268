import { createLayout } from "@/layout/layout";
import { createCardsSection } from "./section/createCardsSection";
import { createReviewsSection } from "./section/createReviewsSection";
import { createSectionMain } from "./section/createSectionMain";
import { createSectionWizzardSection } from "./section/createSectionWizzardSection";

createLayout();
createSectionMain();
createCardsSection();
createSectionWizzardSection();
createReviewsSection();
