import { getMedia } from "@prof-dev/media";

export const createSectionWizzardSection = () => {
  const section = document.querySelector<HTMLElement>(".section-wizzard");

  const items = section?.querySelectorAll<HTMLElement>(".content-item");

  const media = getMedia("(max-width: 767.98px)");

  items?.forEach((item) => {
    const toggle = item.querySelector<HTMLElement>(".item-badge");

    toggle?.addEventListener("click", () => {
      if (media.matches) {
        item.classList.toggle("is-active");
      }
    });
  });
};
