import { Masonry } from "@/lib/Masonry";
import { getMedia } from "@prof-dev/media";
import Swiper from "swiper";

export const createReviewsSection = () => {
  const container = document.querySelector<HTMLElement>(".reviews-list.swiper");

  if (container !== null) {
    const media = getMedia("(min-width: 768px)");

    let swiper: Swiper | undefined;

    const masonry = new Masonry("[data-masonry]", {
      getColumns: () => () => 3,
      getGap: () => () => 20,
    });

    const update = () => {
      if (media.matches) {
        masonry.create();
        swiper?.destroy(true, true);
      } else {
        masonry.destroy();
        swiper = new Swiper(container, {
          slidesPerView: "auto",
          spaceBetween: 20,
        });
      }
    };

    media.addEventListener("change", update);

    update();
  }
};
