import { offBlur, onBlur } from "@/utils/focus";

export class InputV3 extends HTMLElement {
  private input: HTMLInputElement | null;
  private inputContainer: HTMLElement | null;
  private inputClear: HTMLElement | null;
  private inputPasswordToggle: HTMLElement | null;
  private value: string = "";
  private isConfirm: boolean;

  constructor() {
    super();

    this.input = this.querySelector<HTMLInputElement>("[data-input-element]");
    this.inputContainer = this.querySelector<HTMLElement>(
      "[data-input-container]",
    );
    this.inputClear = this.querySelector<HTMLElement>(
      "[data-input-clear-control]",
    );
    this.inputPasswordToggle = this.querySelector<HTMLElement>(
      "[data-password-visibility-control]",
    );

    this.isConfirm = this.classList.contains("is-confirm");

    this.create();
  }

  private setActive = (force: boolean = true) => {
    this.classList.toggle("is-active", force);
  };

  private setClear = (force: boolean = true) => {
    this.classList.toggle("is-clear-visible", force);
  };

  private setValidity = (force: boolean) => {
    this.classList.toggle("is-invalid", force === false);
  };

  private updateStatus = () => {
    if (this.input !== null) {
      const isChanged = this.input.value !== this.value;

      this.classList.toggle("is-changed", isChanged);

      if (document.activeElement === this.input) {
        this.setActive(true);
      } else {
        this.setActive(this.input.value.length !== 0);
      }

      this.setClear(this.input.value.length !== 0);
    } else {
      this.setActive(false);
    }
  };

  private onInput = () => {
    this.updateStatus();

    if (this.isConfirm === true) {
      this.classList.remove("is-confirmed");
    }
  };

  private onFocus = () => {
    this.classList.add("is-focus");
    this.classList.remove("is-error");
    this.input?.focus();
  };

  private onBlur = () => {
    this.classList.remove("is-focus");
  };

  private onPasswordToggle = (event: Event) => {
    event.stopPropagation();

    if (this.input !== null) {
      this.input.setAttribute(
        "type",
        this.input.type === "password" ? "text" : "password",
      );

      const isPasswordVisible = this.input.type !== "password";

      this.classList.toggle("is-password-visible", isPasswordVisible);
    }
  };

  public setValue = (value: string) => {
    if (this.input !== null) {
      this.input.value = value;
      this.input.dispatchEvent(
        new Event("input", {
          bubbles: true,
          cancelable: true,
          composed: true,
        }),
      );
    }
  };

  public clear = () => {
    this.setValue("");
  };

  public reset = () => {
    this.setValue("");
  };

  private validationHandler = () => {
    if (this.input !== null) {
      const validity = this.input.validity;

      if (validity.valid) {
        this.setValidity(true);
      } else {
        this.setValidity(false);
      }
    }
  };

  private inputFocusHander = () => {
    this.setValidity(true);
  };

  private create() {
    if (this.inputContainer !== null) {
      this.inputContainer.addEventListener("click", this.onFocus);
      this.inputContainer.addEventListener("touchstart", this.onFocus);
      onBlur(this.inputContainer, this.onBlur);
    }

    if (this.input !== null) {
      this.value = this.input.value;
      this.input.addEventListener("focus", this.updateStatus);
      this.input.addEventListener("blur", this.updateStatus);
      this.input.addEventListener("input", this.onInput);
      this.input.addEventListener("blur", this.validationHandler);
      this.input.addEventListener("invalid", this.validationHandler);
      this.input.addEventListener("valid", this.validationHandler);
      this.input.addEventListener("focus", this.inputFocusHander);
    }

    if (this.inputClear !== null) {
      this.inputClear.addEventListener("click", this.clear);
    }

    if (this.inputPasswordToggle !== null) {
      this.inputPasswordToggle.addEventListener("click", this.onPasswordToggle);
    }
  }

  private dispose() {
    if (this.inputContainer !== null) {
      this.inputContainer.removeEventListener("click", this.onFocus);
      this.inputContainer.removeEventListener("touchstart", this.onFocus);
      offBlur(this.inputContainer, this.onBlur);
    }

    if (this.input !== null) {
      this.input.removeEventListener("focus", this.updateStatus);
      this.input.removeEventListener("blur", this.updateStatus);
      this.input.removeEventListener("input", this.onInput);
      this.input.removeEventListener("blur", this.validationHandler);
      this.input.removeEventListener("invalid", this.validationHandler);
      this.input.removeEventListener("valid", this.validationHandler);
      this.input.removeEventListener("focus", this.inputFocusHander);

      if (this.input instanceof HTMLTextAreaElement) {
        this.input.style.removeProperty("height");
        this.input.style.removeProperty("max-height");
      }
    }

    if (this.inputClear !== null) {
      this.inputClear.removeEventListener("click", this.clear);
    }

    if (this.inputPasswordToggle !== null) {
      this.inputPasswordToggle.removeEventListener(
        "click",
        this.onPasswordToggle,
      );
    }
  }

  disconnectedCallback() {
    this.dispose();
  }
}
