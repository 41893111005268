import { FaqItem } from "@/components/FaqItem";
import { InputV3 } from "@/components/InputV3";
import { MasonryGrid } from "@/components/MasonryGrid";
import { ReviewsCarousel } from "@/components/ReviewsCarousel";
import { SelectV2 } from "@/components/SelectV2";
import { AjaxForm } from "@/lib/AjaxForm";
import { LazyVideo } from "@/lib/LazyVideo";

export const createLayout = () => {
  window.customElements.define("input-v3", InputV3);
  window.customElements.define("select-v2", SelectV2);
  window.customElements.define("faq-item", FaqItem);
  window.customElements.define("reviews-carousel", ReviewsCarousel);
  window.customElements.define("masonry-grid", MasonryGrid);
  window.customElements.define("lazy-video", LazyVideo);
  window.customElements.define("ajax-form", AjaxForm);
  createHeader();
};

const createHeader = () => {
  const header = document.querySelector(".header");
  const hamburger = header?.querySelector(".hamburger") ?? null;

  hamburger?.addEventListener("click", () => {
    header?.classList.toggle("is-active");
    hamburger.classList.toggle("is-active");
  });

  window.addEventListener("popstate", () => {
    header?.classList.remove("is-active");
    hamburger?.classList.remove("is-active");
  });
};
